import React from "react";

const Error = () => {
  return (
    <div className="flex flex-col h-[100vh] justify-center items-center bg-[#ebf1fa]">
      <img src="/error.png" width={450} alt="" />
      <h2 className="text-[50px]">Error!</h2>
      <p>
        Something went wrong, you might be trying to access an unauthorized
        page.
      </p>
      <a
        className="mt-8"
        href="https://xendwire.com"
        target="_blank"
        rel="noreferrer"
      >
        <button className="bg-[#011638] px-4 py-1 text-white rounded">
          Get started now
        </button>
      </a>
    </div>
  );
};

export default Error;
