/* eslint-disable @typescript-eslint/no-var-requires */
const CryptoJS = require("crypto-js");

const encryptData = async (data: any) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_ENCRYPTION_KEY,
    {
      keySize: 128 / 8,
      iv: CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPTION_IV),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString();
  return { key: process.env.REACT_APP_ENCRYPTION_IV, data: encrypted }.data;
};

const decryptData = async (payload: any) => {
  const decrypted = await JSON.parse(
    CryptoJS.enc.Utf8.stringify(
      CryptoJS.AES.decrypt(payload, process.env.REACT_APP_ENCRYPTION_KEY, {
        keySize: 128 / 8,
        iv: CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPTION_IV),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      })
    )
  );
  return decrypted;
};

export { encryptData, decryptData };
