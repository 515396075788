import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state
interface PaymentState {
  payment: any;
  currencies: any[];
}

// Define the initial state using that type
const initialState: PaymentState = {
    payment: null,
    currencies: [],

}

export const mainSlice = createSlice({
  name: 'xendwire',
  initialState,
  reducers: {
    setPayment: (state, action: PayloadAction<any>) => {
      state.payment = action.payload
    },
    setCurrencies: (state, action: PayloadAction<any>) => {
        state.currencies = action.payload
    },
  },
})

export const { setCurrencies, setPayment } = mainSlice.actions

export default mainSlice.reducer