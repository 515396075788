import axios from "axios";
import { decryptData } from "../utils/encryption";
import { setPayment } from "../redux/reducers/mainReducer";
import { useDispatch } from "react-redux";

const usePayment = () => {
  const dispatch = useDispatch()
  const getPaymentDetails = async (data: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/get_payment_details`,
        {
          data,
        },
        {
          headers: {
            Authorization: "Bearer " + process.env.REACT_APP_API_AUTH_TOKEN,
          },
        }
      );
      const result = await decryptData(response.data.data);
      dispatch(setPayment(result))
    } catch (error) {
      console.log(error);
    }
  };
  return { getPaymentDetails };
};

export default usePayment;
