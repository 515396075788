import axios from "axios";

interface ExchangeRates {
  [key: string]: number;
}

export function convertCurrency(
  amount: number,
  fromCurrency: string,
  toCurrency: string,
  exchangeRates: ExchangeRates
): number {
  const fromRate = exchangeRates[`${fromCurrency}`];
  const toRate = exchangeRates[`${toCurrency}`];

  if (fromRate && toRate) {
    const convertedAmount = (amount / fromRate) * toRate;
    return Number(convertedAmount.toFixed(2));
  }

  throw new Error("Invalid currency code");
}

export async function currencyFilter(assets: any[]) {
  let finalAssets: any[] = [];
  let newAssets: any[] = [];
  for (const asset of assets) {
    const duplicateAssets = assets.filter(
      (item: any) => asset.assetId === item.assetId
    );
    newAssets = [
      ...newAssets,
      {
        ...asset,
        duplicateAssets,
      },
    ];
  }
  const uniqueItems = newAssets.filter((item, index, self) => {
    return index === self.findIndex((t) => t.assertId === item.assertId);
  });
  for (const item of uniqueItems) {
    let networks: any[] = [];
    for (const _item of item.duplicateAssets) {
      networks = [
        ...networks,
        {
          networkId: _item.networkId,
          walletAddress: _item.walletAddress,
        },
      ];
    }
    finalAssets = [
      ...finalAssets,
      {
        id: item.id,
        assetId: item.assetId,
        sourceId: item.sourceId,
        networks,
      },
    ];
  }
  return finalAssets;
}

export async function convertUSDTtoBTC(usdtPrice: string | number, currency: string) {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_BINANCE_API}=${`${currency}`}USDT`);
    const btcUSDTPrice = data.price;
    const btcPrice = +usdtPrice / +btcUSDTPrice;
    return btcPrice;
  } catch (error) {
    console.log(error);
  }
}
