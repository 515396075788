import { useEffect, useState } from "react";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";
import { convertCurrency } from "../helpers";
import { useSelector } from "react-redux";

const fiats = ["USD", "EUR", "NGN", "XAF", "GBP", "ZAR"];

const Details = () => {
  const { payment } = useSelector((state: any) => state.main);
  const [amount, setAmount] = useState<number>(0);
  const [showRate, setShowRate] = useState(false);
  const [rate, setRate] = useState("USD");

  const selectRate = (_rate: any) => {
    setRate(_rate);
    setShowRate(!showRate);
  };

  useEffect(() => {
    if (payment) {
      const _amount = convertCurrency(
        payment?.amount,
        "USD",
        rate,
        payment?.rates
      );
      setAmount(_amount);
    }
  }, [payment, rate]);

  return (
    <div className="bg-white w-full mb-5 rounded p-3">
      <p>{payment?.description}</p>
      <div className="w-full flex justify-between items-center">
        <div className="text-sm">
          Amount:{" "}
          <span className="font-bold">
            {rate === "USD" && "$"}
            {rate === "GBP" && "£"}
            {amount.toLocaleString()}
            {" "} {rate === "XAF" && "XAF"}
            {rate === "EUR" && "EUR"}
            {rate === "NGN" && "NGN"}
            {rate === "ZAR" && "ZAR"}
          </span>
        </div>
        <div>
          <div
            className="flex justify-center items-center  border-[1px] p-1 rounded cursor-pointer mb-2 w-[100px]"
            onClick={() => setShowRate(!showRate)}
          >
            {rate}
            {showRate ? (
              <MdOutlineArrowDropUp className="ml-1" size={20} />
            ) : (
              <MdOutlineArrowDropDown className="ml-1" size={20} />
            )}
          </div>
          {showRate && (
            <div className="absolute bg-white w-[120px] ml-[-50px] border-[1px] rounded">
              {fiats.map((fiat, index) => (
                <div
                  onClick={() => selectRate(fiat)}
                  className="flex p-2 items-center cursor-pointer hover:bg-gray-200 justify-center"
                  key={index}
                >
                  <p>{fiat}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Details;
