/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { AiOutlineCopy, AiFillCheckCircle } from "react-icons/ai";
import Currencies from "../components/Currencies";
import { BallTriangle, TailSpin } from "react-loader-spinner";
import toast from "react-hot-toast";
import { FaArrowLeft } from "react-icons/fa6";
import socket from "../socket";
import { v4 as uuid } from "uuid";
import usePayment from "../hooks/usePayment";
import Details from "../components/Details";
import useAssets from "../hooks/useAssets";
import { useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

socket.connect();
let orderId = window.localStorage.getItem("orderId");

if (orderId) {
  socket.emit("joinRoom", orderId);
} else {
  const id = uuid();
  const IDs = id.split("-");
  orderId = IDs[IDs?.length - 1];
  window.localStorage.setItem("orderId", orderId);
  socket.emit("joinRoom", orderId);
}

// Get the URL parameters
const urlParams: any = new URLSearchParams(window.location.search);
const keyParam = urlParams.get("key");

const Payment = () => {
  const { payment } = useSelector((state: any) => state.main);
  const { getPaymentDetails } = usePayment();
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [userEmail, setEmail] = useState<any>("");
  const [seconds, setSeconds] = useState(120 * 60);
  const [timedOut, setTimeOut] = useState(false);
  const { getAssets } = useAssets();

  useEffect(() => {
    setLoading(true);
    socket.on("message", (wallet: string) => {
      setAddress(wallet);
      setLoading(false);
    });
    const email = window.localStorage.getItem("xendwire_user_email");
    setEmail(email);
  }, []);

  useEffect(() => {
    getPaymentDetails(keyParam);
  }, [keyParam]);

  useEffect(() => {
    if (payment) {
      getAssets(payment?.id);
    }
  }, [payment]);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(address);
      toast.success("Address copied!");
    } catch (error) {
      console.error("Failed to copy to clipboard: ", error);
      toast.error("Failed to copy to clipboard!");
    }
  };

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          socket.disconnect();
          clearInterval(countdownInterval);
          setTimeOut(true);
          return 0;
        }
      });
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(countdownInterval);
  }, []);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const registerEmail = (e: any) => {
    e.preventDefault();
    window.localStorage.setItem("xendwire_user_email", userEmail);
    toast.success("Email submitted");
  };

  if (!payment)
    return (
      <div className="w-full h-[100vh] flex items-center justify-center">
        <BallTriangle
          height={100}
          width={100}
          radius={5}
          color="#011638"
          ariaLabel="ball-triangle-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  return (
    <>
      {timedOut ? (
        <TimedOut />
      ) : (
        <div className="flex flex-col h-[100vh] items-center justify-center bg-[#ebf1fa]">
          <div className="flex w-[70%]">
            <div className="w-full flex flex-col px-[20px] mt-[50px] border-r-2 h-[70%]">
              <div className="flex items-center mb-3">
                <FaArrowLeft />
                <p className="ml-2">
                  Make Payment for{" "}
                  <span className="font-medium">{payment?.payment}</span>
                </p>
              </div>
              <Details />
              <div className="">
                <ul>
                  <li>
                    <span className="text-gray-500">Order ID:</span>{" "}
                    <span className="text-sm">{orderId}</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full flex flex-col items-center justify-center px-[20px] mt-3">
              <div className="flex flex-col w-[90%] justify-center items-start">
                <div className="flex items-center mb-3 md:hidden">
                  <FaArrowLeft />
                  <p className="ml-2">
                    Make Payment for{" "}
                    <span className="font-medium">Lucydbrand</span>
                  </p>
                </div>
                <div className="flex flex-col justify-center items-center w-full">
                  <div className="flex items-center justify-between w-full px-2 py-2">
                    <div className="text-xs flex items-center w-">
                      <TailSpin
                        height="80"
                        width="25"
                        color="#f56530"
                        ariaLabel="tail-spin-loading"
                        radius="0.1"
                        wrapperStyle={{}}
                        visible={true}
                      />
                      <p className="ml-2">Waiting for Payment</p>
                    </div>
                    <div className="bg-[#011638] h-[1px] w-[30%] rounded-full"></div>
                    <div
                      className="flex text-xs items-center"
                      style={{
                        color: "#aaa",
                      }}
                    >
                      <AiFillCheckCircle size={17} />
                      <p className="ml-2">Processing Payment</p>
                    </div>
                  </div>
                  <div className="border-[1px]  border-[#011638] w-[20%] rounded flex justify-center items-center mb-2">
                    <span>
                      {minutes}:{remainingSeconds < 10 ? "0" : ""}
                      {remainingSeconds}
                    </span>
                  </div>
                  <div className="flex flex-col justify-center items-center w-[100%]">
                    <Currencies />
                    <div className="flex justify-center  mb-4">
                      <div className="p-1 bg-white rounded">
                        {loading ? (
                          <Skeleton height={160} width={160} />
                        ) : (
                          <QRCode
                            fgColor="#011638"
                            logoImage="/icon.png"
                            logoPaddingStyle="circle"
                            eyeRadius={10}
                            removeQrCodeBehindLogo={true}
                            qrStyle="dots"
                            bgColor="white"
                            value={address}
                          />
                        )}
                      </div>
                    </div>

                    {loading ? (
                      <div className="mb-3">
                        <SkeletonTheme
                          baseColor="#f4f4f4"
                          highlightColor="#fff"
                        >
                          <Skeleton height={35} width={300} />
                        </SkeletonTheme>
                      </div>
                    ) : (
                      <div className="flex justify-between w-full mb-3">
                        <div className="bg-white w-[100%] rounded p-2 text-sm">
                          <p className="text-xs">{address}</p>
                        </div>
                        <button
                          onClick={copyToClipboard}
                          className="bg-white rounded p-2 ml-2"
                        >
                          <AiOutlineCopy />
                        </button>
                      </div>
                    )}

                    <div className="w-full">
                      <p className="text-xs mb-2">
                        Please provide your email, to be notified of the payment
                        status (optional).
                      </p>
                    </div>
                    <form
                      onSubmit={registerEmail}
                      className="flex w-full justify-between items-center"
                    >
                      <div className="w-[80%] bg-white rounded p-2">
                        <input
                          type="email"
                          placeholder="Email"
                          className="w-full outline-none"
                          value={userEmail}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <button
                        className="p-2 bg-[#011638] rounded text-white"
                        type="submit"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center mt-[80px]">
            <p className="text-sm">Powered by</p>
            <a
              className="mt-[-28px]"
              href="https://xendwire.com"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/logo.png" width={180} alt="" />
            </a>
          </div>
        </div>
      )}
    </>
  );
};

const TimedOut = () => {
  return (
    <div className="flex flex-col h-[100vh] justify-center items-center bg-[#ebf1fa]">
      <div className="mb-5">
        <img
          src="/timed_out_dark.png"
          width={450}
          className="rounded-full"
          alt=""
        />
      </div>
      <p className="font-bold">Oops! Session timed out.</p>
      <p className="mb-5">Please reload to make payment.</p>
      <div className="flex justify-between w-[10%]">
        <button className="bg-white px-4 py-1 rounded">Back</button>
        <button
          className="bg-[#011638] px-4 py-1 text-white rounded"
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload
        </button>
      </div>
      <div className="flex flex-col items-center mt-[150px]">
        <p className="text-sm">Powered by</p>
        <a
          className="mt-[-28px]"
          href="https://xendwire.com"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/logo.png" width={180} alt="" />
        </a>
      </div>
    </div>
  );
};

export default Payment;
