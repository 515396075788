import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Payment from "./pages/Payment";
import Error from "./pages/Error";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/payment/*" element={<Payment />} />
        <Route path="/*" element={<Error />} />
      </Routes>
      <Toaster />
    </Router>
  );
}

export default App;
