import { useDispatch } from "react-redux";
import { decryptData } from "../utils/encryption";
import axios from "axios";
import { setCurrencies } from "../redux/reducers/mainReducer";

const useAssets = () => {
  const dispatch = useDispatch()
  const getAssets = async (token: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get_payment_assets/${token}`,
        {
          headers: {
            Authorization: "Bearer " + process.env.REACT_APP_API_AUTH_TOKEN,
          },
        }
      );
      const result = await decryptData(response.data.data);
      console.log(result);
      dispatch(setCurrencies(result))
    } catch (error) {
      console.log(error);
    }
  };
  return { getAssets };
};

export default useAssets;
