/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";
import { useSelector } from "react-redux";
import socket from "../socket";
import { convertUSDTtoBTC } from "../helpers";
let orderId = window.localStorage.getItem("orderId");
const Currencies = () => {
  const { currencies, payment } = useSelector((state: any) => state.main);
  const [showCurrencies, setShowCurrencies] = useState(false);
  const [showNetworks, setShowNetworks] = useState(false);
  const [network, setNetwork] = useState<any>({});
  const [amount, setAmount] = useState<any>();
  const [assets, setAssets] = useState<any[]>([]);
  const [currency, setCurrency] = useState<any>({});

  const selectCurrency = (_currency: any) => {
    setCurrency(_currency);
    setNetwork(_currency?.networks[0]);
    setShowCurrencies(!showCurrencies);
  };

  const selectNetwork = (_network: any) => {
    setNetwork(_network);
    setShowNetworks(!showNetworks);
  };

  useEffect(() => {
    if (currencies?.length > 0) {
      const cryptos: any[] = [...currencies];
      setCurrency(cryptos[0]);
      setNetwork(cryptos[0]?.networks[0]);
      setAssets(cryptos);
    }
  }, [currencies]);

  useEffect(() => {
    if (payment && currencies?.length > 0) {
      socket.emit("create_wallet", {
       meta: {
        orderId,
        type: network?.type,
       },
       data: {
        orderId,
        paymentId: payment?.id,
        assetId: currency?.id,
        networkId: network?.id,
       }
      });
    }
  }, [currency, network]);

  useEffect(() => {
    if (currency?.symbol === "BTC") {
      convertUSDTtoBTC(payment?.amount, "BTC").then((amount: any) =>
        setAmount(amount)
      );
    } else if (currency?.symbol === "ETH") {
      convertUSDTtoBTC(payment?.amount, "ETH").then((amount: any) =>
        setAmount(amount)
      );
    } else {
      setAmount(payment?.amount);
    }
  }, [currency]);

  return (
    <div className="bg-white w-full mb-5 rounded p-3">
      <div className="flex w-full justify-between mb-3">
        <div>
          <span className="text-xs text-[#aaa]">Currency</span>
          <div
            className="flex justify-center items-center  border-[1px] p-1 rounded cursor-pointer mb-2"
            onClick={() => setShowCurrencies(!showCurrencies)}
          >
            <img src={currency.icon} className="mx-2" width={20} alt="" />
            <span>{currency.symbol}</span>
            {showCurrencies ? (
              <MdOutlineArrowDropUp className="ml-1" size={20} />
            ) : (
              <MdOutlineArrowDropDown className="ml-1" size={20} />
            )}
          </div>

          {showCurrencies && (
            <div className="absolute bg-white w-[150px] border-[1px] rounded">
              {assets.map((_currency: any, index: number) => (
                <div
                  onClick={() => selectCurrency(_currency)}
                  className="flex p-2 items-center cursor-pointer hover:bg-gray-200"
                  key={index}
                >
                  <img src={_currency.icon} width={20} alt="" />
                  <p className="ml-2">{_currency.symbol}</p>
                </div>
              ))}
            </div>
          )}
        </div>
        <div>
          <span className="text-xs text-[#aaa]">Network</span>
          <div
            className="flex justify-center items-center w-[100px] border-[1px] p-1 rounded cursor-pointer mb-2"
            onClick={() => setShowNetworks(!showNetworks)}
          >
            {network?.name}
            {showNetworks ? (
              <MdOutlineArrowDropUp className="ml-1" size={20} />
            ) : (
              <MdOutlineArrowDropDown className="ml-1" size={20} />
            )}
          </div>
          {showNetworks && (
            <div className="absolute bg-white w-[120px] border-[1px] flex flex-col items-center justify-center rounded">
              {currency.networks.map((network: any, index: number) => (
                <div
                  onClick={() => selectNetwork(network)}
                  className="flex p-2 w-full items-center cursor-pointer hover:bg-gray-200"
                  key={index}
                >
                  <p>{network.name}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="mb-2 text-sm">
        {amount} {currency.symbol}
      </div>
    </div>
  );
};

export default Currencies;
